
import Http from './Http'

export default {
    async getMatchMotm(match_id) {
        return await Http.get(`/games/motm/match/${match_id}/all`)
    },
    async getMatchScorePrediction(match_id) {
        // return await Http.get(`/games/gts/match/${match_id}`)  this route doesn't exsist
        return await Http.get(`/games/gts/match/${match_id}/all`)
    },
    async getMatchMotmData(match_id) {
        return await Http.get(`/games/motm/${match_id}`)
    },
    async exportMotmData(match_id,data) {
        return await Http.get(`/games/motm/match/${match_id}/export` , data)
    },
    async saveMOTMvotes(match_id, data){
        return await Http.post(`/games/motm/adminvotes/${match_id}`, data)
    },
    async getLeaderboardData(data) {
        return await Http.get(`/games/leaderboard?${data}`)
    },
    async getLeaderboardDataSetup(data) {
        return await Http.get(`/games/leaderboard/setup?${data}`)
    },
    async getLeaderboardMiniSetup() {
        return await Http.get(`/games/leaderboard/mini/setup`)
    },
    async getLeaderboardMini(data) {
        return await Http.get(`/games/leaderboard/mini?${data}`)
    },
    async getScorePrediction(customer_id, data) {
        return await Http.get(`/games/gts/customer/${customer_id}`, data);
    },
    async getCurrentMatches(data) {
        return await Http.get(`/football/matches/current`, data);
    },
    async getAllWords(data) {
        return await Http.get(`/games/word/all?${data}`)
    },
    async deleteWord(language, word) {
        return await Http.delete(`/games/word/${language}/${word}`)
    },
    async uploadWords(data){
        return await Http.post(`/games/word/upload`, data)
    }
}


